import styled from '@emotion/styled';
import { AnimatePresence, motion, MotionProps } from 'framer-motion';
import React, { HTMLAttributes, ReactNode } from 'react';
import RoundFilledButton, { RoundFilledButtonProps } from 'components/toolbox/croquis/Button/RoundFilled';
import { usePreventScroll } from 'hooks';
import { black, gray700, WEB_MAX_WIDTH, white } from 'styles';
import { typography } from 'styles/font';

export interface BottomDialogButtonOption extends RoundFilledButtonProps {
  id?: string;
  onClick?: () => void;
}

export interface BottomDialogOption {
  image?: React.ReactNode;
  title?: React.ReactNode;
  text?: React.ReactNode;
  buttons?: BottomDialogButtonOption[];
  disableBackdropClick?: boolean;
  canBackDropResolve?: boolean;
  children?: ReactNode;
  radius?: boolean;
  containerHeight?: string;
  /**
   * @description window scroll을 할 수 있게 하는지 여부
   * @default false
   */
  canScroll?: boolean;
  buttonDirection?: 'column' | 'row';
  onClose?: () => void;
}

type ContainerProps = Omit<HTMLAttributes<HTMLDivElement>, 'title' | keyof MotionProps> & MotionProps;

export interface BottomDialogProps extends BottomDialogOption, ContainerProps {
  active?: boolean;
  handleOutsideClick?: () => void;
}

/** useBottomDialog 와 Dialog에 사용하기 위한 컴포넌트  */
export default function BottomDialog({
  image,
  title,
  text,
  buttons,
  disableBackdropClick,
  children,
  radius,
  buttonDirection,
  active,
  containerHeight,
  canScroll,
  handleOutsideClick,
  ...rest
}: BottomDialogProps) {
  usePreventScroll(Boolean(canScroll) === false && Boolean(active) === true);
  return (
    <AnimatePresence>
      {active && (
        <Overlay
          animate={{ background: 'rgba(73, 80, 87, 0.7)' }}
          {...(!disableBackdropClick && { onClick: handleOutsideClick })}
        >
          <Container
            {...rest}
            initial={{ translateY: '100%' }}
            animate={{ translateY: 0 }}
            exit={{ translateY: '100%' }}
            transition={{ type: 'tween' }}
            css={{ ...(radius === false && { borderRadius: '0px' }), height: containerHeight }}
          >
            {children ? (
              children
            ) : (
              <>
                {image && image}
                {title && <Title>{title}</Title>}
                {text && <Text>{text}</Text>}
                {buttons && (
                  <ButtonWrapper flexDirection={buttonDirection}>
                    {buttons.map((button_props) => (
                      <StyledRoundFilledButton key={button_props.id} {...button_props} />
                    ))}
                  </ButtonWrapper>
                )}
              </>
            )}
          </Container>
        </Overlay>
      )}
    </AnimatePresence>
  );
}

const Overlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 9999;
`;

const Title = styled.div`
  ${typography.title5}
  color: ${black};
  padding: 0 24px;
  margin: 8px 0;
  white-space: pre-wrap;
`;

const Text = styled.div`
  ${typography.body3}
  color: ${gray700};
  padding: 0 24px;
  margin: 0 0 24px;
  white-space: pre-wrap;
`;

const Container = styled(motion.div)`
  position: absolute;
  z-index: 9999;
  margin: 0 auto;
  padding: 40px 0 11px;
  padding-bottom: calc(env(safe-area-inset-bottom) + 11px);
  padding-bottom: calc(constant(safe-area-inset-bottom) + 11px);
  width: 100%;
  max-width: ${WEB_MAX_WIDTH}px;
  background: ${white};
  border-radius: 8px 8px 0 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
`;
const ButtonWrapper = styled.div<{ flexDirection?: string }>`
  display: flex;
  justify-content: space-around;
  padding: 0 20px;
  flex-direction: ${({ flexDirection }) => flexDirection};
`;

const StyledRoundFilledButton = styled(RoundFilledButton)`
  margin: 8px 4px 0;
  width: 100%;
  display: block;
  cursor: pointer;
`;
