import { cx } from '@emotion/css';
import { css } from '@emotion/react';
import Link, { LinkProps } from 'next/link';
import React, { forwardRef, memo, useMemo } from 'react';
import { gray300, gray800, pink100, pink200, pink500, pink600, Typography, white } from 'styles';
export type RoundFilledButtonSize = 'sm' | 'md' | 'lg';
export type RoundFilledButtonStatus = 'pressed' | 'normal' | 'disabled';
export type RoundFilledVariant = 'default' | 'primary' | 'second';

// TODO: 린트 제거 후 타입 수정 하기
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export interface RoundFilledButtonProps
  extends React.HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>,
    Omit<LinkProps, 'href'> {
  /** @default 'md' */
  size?: RoundFilledButtonSize;
  /**
   * @default 'normal'
   */
  status?: RoundFilledButtonStatus;
  /** @default 'primary' */
  variant?: RoundFilledVariant;
  /**
   * href가 있으면 a 태그 역활을 합니다.
   */
  href?: string;
  /**
   * @deprecated :active 스타일 변경을 금지한다.
   */
  disable_active?: boolean;
  disabled?: boolean;
  type?: 'button' | 'reset' | 'submit';
}

const styleBySize = (size?: RoundFilledButtonSize) => {
  switch (size) {
    case 'sm':
      return css`
        padding: 8px;
        min-height: 32px;
      `;
    case 'lg':
      return css`
        padding: 15px;
        min-height: 46px;
      `;
    case 'md':
    default:
      return css`
        padding: 12px;
        min-height: 40px;
      `;
  }
};

const typographyBySize = (size?: RoundFilledButtonSize) => {
  switch (size) {
    case 'lg':
      return Typography.BUTTON;
    case 'md':
    case 'sm':
    default:
      return Typography.BUTTON_SMALL;
  }
};

const switchByVariant = (variant?: RoundFilledButtonStatus) => (pressed: string, normal: string, disabled: string) => {
  switch (variant) {
    case 'disabled':
      return disabled;
    case 'pressed':
      return pressed;
    case 'normal':
    default:
      return normal;
  }
};

const colorByTypeVariant = (type?: RoundFilledVariant, variant?: RoundFilledButtonStatus, disable_active?: boolean) => {
  const switchColor = switchByVariant(variant);
  switch (type) {
    case 'default':
      return css`
        background: ${switchColor(pink600, pink500, pink500)};
        color: ${switchColor(white, white, white)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(pink600, pink500, pink500)};
        }
        &:active {
          background: ${!disable_active && pink200};
        }
      `;
    case 'second':
      return css`
        background: ${switchColor(pink600, pink500, pink100)};
        color: ${switchColor(white, white, white)};
        &:link,
        &:active,
        &:visited {
          color: ${switchColor(white, white, white)};
        }
        &:active {
          background: ${!disable_active && pink600};
        }
      `;
    case 'primary':
    default:
      return css`
        background: white;
        color: ${gray800};
        border: 1px solid ${gray300};

        &:link,
        &:active,
        &:visited {
          color: ${switchColor(white, white, white)};
        }
        &:active {
          background: ${!disable_active && white};
        }
      `;
  }
};

const default_style = css`
  white-space: pre;
`;

const RoundFilledButton = memo(
  forwardRef<HTMLButtonElement & HTMLAnchorElement, RoundFilledButtonProps>(
    ({ className, size, status, variant, href, ...props }, ref) => {
      const typography = useMemo(() => typographyBySize(size), [size]);
      const padding = useMemo(() => styleBySize(size), [size]);
      const color = useMemo(() => {
        const style_status = props.disabled ? 'disabled' : status;
        return colorByTypeVariant(variant, style_status);
      }, [status, variant, props.disabled]);
      const classNames = useMemo(() => cx(typography, className), [className, typography]);
      if (href) {
        const { as, replace, scroll, shallow, passHref, prefetch, locale, children, ...rest_props } = props;
        return (
          <Link
            {...rest_props}
            className={classNames}
            ref={ref}
            css={css`
              ${default_style}
              ${padding}
            ${color}
            `}
            href={href}
            {...{ as, replace, scroll, shallow, passHref, prefetch, locale }}
          >
            {children}
          </Link>
        );
      }
      return (
        <button
          {...props}
          className={classNames}
          ref={ref}
          css={css`
            ${default_style}
            ${padding}
      ${color}
          `}
        />
      );
    },
  ),
);
export default RoundFilledButton;
